import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { FinalizadoraProps } from './protocols';
import { InputContainer } from './styles';
import { Form, Spinner } from 'react-bootstrap';
import { useInfiniteQuery, useQueryClient } from 'react-query';
import { Controller } from 'react-hook-form';
import { toast } from 'react-toastify';
import api from '~/services/api';
import useDebounce from '~/hooks/useDebounce';
import { Lista } from './Lista';

/**
 * @param {FinalizadoraProps} Finalizadora
 */

export const Finalizadora: React.FC<FinalizadoraProps> = ({
  label = '',
  placeholder = '',
  name,
  register,
  isError,
  control,
  changeSelected,
  loja,
  retriever,
  ...rest
}: FinalizadoraProps) => {
  /**
   * debouncedFn
   * Hook de Debounce usado para efetuar a busca após digitação do usuário
   */
  const { debouncedFn } = useDebounce();
  /**
   * inputRef
   * Ref do input utilizado para setar a label da opção selecionada no input
   */
  const inputRef: any = useRef(null);
  /**
   * selected, setSelected
   * Opção selecionada da lista
   */
  const [selected, setSelected] = useState<any>({
    label: undefined,
    value: undefined,
  });

  /**
   * isOpen
   * Renderiza ou não a lista
   */
  const [isOpen, setIsOpen] = useState<boolean>(false);
  /**
   * filter
   * Filtro passado para a api
   */
  const [filter, setFilter] = useState<string>('');
  /**
   * limit
   * Quantidade de registros buscados por vez na api
   */
  const limit = 10;
  /**
   * fetchData
   * Método que se comunica com a api
   */

  const filterFinalizadoras = useCallback(
    (options: any[]): any[] => {
      const filteredOptions = options.filter((item) =>
        item.finalizadora.des_finalizadora
          .toLowerCase()
          .includes(filter.toLowerCase()),
      );
      return filteredOptions;
    },
    [filter],
  );

  const fetchData = useCallback(
    async ({ pageParam = 1 }): Promise<any> => {
      try {
        if (loja) {
          const cache_finalizadoras: any = queryClient.getQueryData(
            'cache_finalizadoras',
          );
          if (cache_finalizadoras && cache_finalizadoras.loja === loja) {
            return cache_finalizadoras;
          }

          const { data: dataFields } = await api.get(
            `/finalizadora-loja/${loja}`,
            {
              params: {
                page: pageParam,
                limit,
                filter,
              },
            },
          );

          const options = filterFinalizadoras(dataFields.data)
            .filter((d: any) => d.flg_inativo !== true)
            .map((dt: any) => {
              return {
                value: dt.cod_finalizadora,
                label: `${dt.finalizadora.des_finalizadora}`,
                ...dt,
              };
            });

          // Ordenação
          const optionsOrder = options.sort(function (a: any, b: any) {
            if (a.label < b.label) {
              return -1;
            }
            if (a.label > b.label) {
              return 1;
            }
            return 0;
          });
          queryClient.setQueryData(`cache_finalizadoras`, {
            ...dataFields,
            data: optionsOrder,
          });

          return {
            ...dataFields,
            data: optionsOrder,
            loja,
          };
        }
        return {
          count: '0',
          data: [],
          fields: [],
          pagination: {
            lastPage: 0,
            prevPage: 1,
            startPage: 1,
          },
          success: true,
        };
      } catch (error: any) {
        if (error.data?.message) {
          toast.error(error.data.message);
        } else {
          toast.error(String(error));
        }
        return {
          count: '0',
          data: [],
          fields: [],
          pagination: {
            lastPage: 0,
            prevPage: 1,
            startPage: 1,
          },
          success: true,
        };
      }
    },
    [isOpen, loja, filter, filterFinalizadoras],
  );

  /**
   * useInfiniteQuery
   * Hook do react-query para listagem infinita de dados
   */
  const queryClient = useQueryClient();
  const { data, hasNextPage, fetchNextPage, isFetching, refetch } =
    useInfiniteQuery(name, ({ pageParam = 1 }) => fetchData({ pageParam }), {
      refetchOnWindowFocus: false,
    });

  useEffect(() => {
    refetch();
  }, [loja]);

  return (
    <InputContainer>
      <Form.Group>
        <Form.Label>{label}</Form.Label>
        <Controller
          name={name}
          control={control}
          defaultValue=""
          render={({ field: { value } }) => {
            if (inputRef && inputRef.current) {
              /** A condicional abaixo limpa o input */
              if (
                value.label !== selected.label &&
                inputRef.current.placeholder !== placeholder
              ) {
                inputRef.current.placeholder = placeholder;
              }

              if (value.label) {
                inputRef.current.placeholder = `${value.label}`;
              }
              if (!value.label) {
                inputRef.current.placeholder = placeholder;
              }
            }
            return (
              <>
                <div className="select-container">
                  <label>
                    <input
                      {...register(name)}
                      type="text"
                      className={
                        isError ? 'form-control is-invalid' : 'form-control'
                      }
                      placeholder={placeholder}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        event.target.value = event.target.value.toUpperCase();
                        setFilter(event.target.value.toUpperCase());
                        if (event.target.value === '') {
                          queryClient.removeQueries(name);
                        }
                        debouncedFn(() => refetch(), 500);
                      }}
                      autoComplete="off"
                      onClick={(event: any) => {
                        if (selected.label) {
                          event.target.placeholder = selected.label;
                        }
                        event.target.value = '';
                        setIsOpen(true);
                      }}
                      ref={inputRef}
                      {...rest}
                    />
                    <div className="drop-indicator">
                      <span role="img" aria-label="open">
                        {filter && isFetching ? (
                          <Spinner
                            animation="border"
                            size="sm"
                            className="spinner"
                          />
                        ) : (
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            role="presentation"
                          >
                            <path
                              d="M8.292 10.293a1.009 1.009 0 000 1.419l2.939 2.965c.218.215.5.322.779.322s.556-.107.769-.322l2.93-2.955a1.01 1.01 0 000-1.419.987.987 0 00-1.406 0l-2.298 2.317-2.307-2.327a.99.99 0 00-1.406 0z"
                              fill="currentColor"
                              fillRule="evenodd"
                            />
                          </svg>
                        )}
                      </span>
                    </div>
                  </label>
                </div>
                {isOpen && (
                  <Lista
                    inputRef={inputRef}
                    setIsOpen={setIsOpen}
                    setFilter={setFilter}
                    hasNextPage={hasNextPage}
                    fetchNextPage={fetchNextPage}
                    data={data}
                    selected={selected}
                    setSelected={setSelected}
                    isFetching={isFetching}
                    queryName={name}
                    changeSelected={changeSelected}
                    queryClient={queryClient}
                  />
                )}
              </>
            );
          }}
        />
      </Form.Group>
    </InputContainer>
  );
};
